import React from "react"
import { useTranslation } from "react-i18next"
import { Container, Typography, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import axios from "axios"

const PaidOrderFailed = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [orderId, setOrderId] = React.useState()
  const [errMessage, setErrMessage] = React.useState()

  React.useEffect(() => {
    if (windowGlobal) {
      setOrderId(windowGlobal.location.search.substr(4))
    }
  }, [windowGlobal])

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  if (windowGlobal) {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-order-payment-error/${orderId}`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
        },
      })
      .then(res => {
        setErrMessage(res.data.localiziedMessage)
      })
  }

  return (
    <>
      <SEO title={t("paidOrder.title")} lang={i18n.language} />
      <Container className={classes.container}>
        <Typography className={classes.textSuccess}>{errMessage ? errMessage : t("paidOrder.error")}</Typography>
      </Container>
    </>
  )
}

export default PaidOrderFailed

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "15vw",
    paddingTop: 40,
    paddingBottom: 40,
  },
  textSuccess: {
    border: "2px solid #ff3333",
    width: "max-content",
    margin: "0 auto",
    textAlign: "center",
    padding: "10px 16px",
    borderRadius: 4,
  },
}))

const windowGlobal = typeof window !== "undefined" && window
